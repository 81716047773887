import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { ContactForm } from "@/components/contact-form";
import { Timeline } from "@/components/timeline";
import { Arrow } from "@/components/arrow";
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo";
import { Layout } from "@/components/layout";

const QAAutomation = () => {
  return (
    <>
      <GatsbySeo
        title="QA software development | QA Software solutions and Services"
        description="Tectra Technologies recognizes the need for quality in tech solutions enabling businesses to sustain in the ultra-competitive digital world."
        canonical="https://www.tectratechnologies.com/qa-automation/"
        openGraph={{
          locale: "en_US",
          type: "article",
          url: "https://www.tectratechnologies.com/qa-automation/",
          title: "QA software development | QA Software solutions and Services",
          description:
            "Tectra Technologies recognizes the need for quality in tech solutions enabling businesses to sustain in the ultra-competitive digital world.",
          site_name: "Tectra Technologies",
        }}
        twitter={{
          handle: "@handle",
          site: "@TectraL",
          cardType: "summary_large_image",
        }}
      />
      <JsonLd
        json={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          "@graph": [
            {
              "@type": "WebSite",
              "@id": "https://www.tectratechnologies.com/#website",
              url: "https://www.tectratechnologies.com/",
              name: "Tectra Technologies",
              description:
                "Web &amp; Mobile App, Cloud, UI/UX, Devops, Tax Services, Digital Marketing",
              potentialAction: [
                {
                  "@type": "SearchAction",
                  target: {
                    "@type": "EntryPoint",
                    urlTemplate:
                      "https://www.tectratechnologies.com/?s={search_term_string}",
                  },
                  "query-input": "required name=search_term_string",
                },
              ],
              inLanguage: "en-US",
            },
            {
              "@type": "WebPage",
              "@id": "https://www.tectratechnologies.com/qa-automation/",
              url: "https://www.tectratechnologies.com/qa-automation/",
              name: "QA software development | QA Software solutions and Services",
              isPartOf: {
                "@id": "https://www.tectratechnologies.com/#website",
              },
              datePublished: "2020-06-23T19:10:49+00:00",
              dateModified: "2021-07-14T10:28:12+00:00",
              description:
                "Tectra Technologies recognizes the need for quality in tech solutions enabling businesses to sustain in the ultra-competitive digital world.",
              breadcrumb: {
                "@id":
                  "https://www.tectratechnologies.com/qa-automation/#breadcrumb",
              },
              inLanguage: "en-US",
              potentialAction: [
                {
                  "@type": "ReadAction",
                  target: ["https://www.tectratechnologies.com/qa-automation/"],
                },
              ],
            },
            {
              "@type": "BreadcrumbList",
              "@id":
                "https://www.tectratechnologies.com/qa-automation/#breadcrumb",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Home",
                  item: "https://www.tectratechnologies.com/",
                },
                { "@type": "ListItem", position: 2, name: "QA Automation" },
              ],
            },
          ],
        }}
      />
      <Layout>
        <div className="bg-white h-screen flex items-center justify-center">
          <div className="w-full max-w-7xl mx-auto px-5 lg:px-8">
            <div className="text-left xl:w-3/5">
              <div className="">
                <h1 className="text-black text-4xl font-poppins font-semibold mb-4 normal-case leading-relaxed">
                  Enabling business to sustain in the dynamic digital space.
                </h1>
                <div className="relative flex overflow-hidden justify-start items-center text-center">
                  <Link
                    to="/contact"
                    className="flex items-center text-xl normal-case hover:text-black"
                    activeClassName="border-b-2 border-black"
                  >
                    Talk to us
                    <Arrow color="black" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white mt-10 lg:mt-12">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-center text-2xl font-poppins font-semibold captalize">
              Our proficiency in software testing
            </h2>
            <p className="max-w-5xl mx-auto text-center text-base text-black font-poppins font-thin leading-8 mt-4">
              At Tectra Technologies, our team of experts has a humongous
              experience with technical application testers. Our team provides
              web application testing to help you in testing the application
              that is on the web as well as the mobile application. With our
              logistic testing, we assure you complete satisfaction and the best
              ever service. Next, our datacom testing covers a huge area of the
              organization from the financial to manufacturing to government
              industries. Our team uses high-quality techniques to provide you
              with premium quality assurance service. Our team has gained a huge
              knowledge of various tests by actually working on them for
              different organizations. Thus, we have real-time knowledge about
              different software. We offer you extensive QA automation services
              that heels results.
            </p>
            <div className="mt-8">
              <div className="w-full h-full">
                <StaticImage
                  src="../../images/LJ9KY8pIH3Ess.png"
                  alt=""
                  layout="constrained"
                  placeholder="blurred"
                  quality={95}
                  height={500}
                  className="w-full h-60 md:h-92 lg:h-auto"
                  formats={["auto", "png"]}
                />
              </div>
              <div className="mt-8 lg:mt-12">
                <h2 className="text-black text-center text-2xl font-poppins font-semibold captalize">
                  Why us for QA automation?
                </h2>
                <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                  Our extensive expertise and in-depth knowledge help us with a
                  delivery project with tailor-made automation solutions. Here
                  are the reasons to choose us:
                </p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-8 mt-8 lg:mt-12">
                <div className="text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    Optimum precision
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    Our team test faster with 99% accuracy with our high-end
                    tools. Our tests are done with the assistance of data-driven
                    tools and hence you get optimum precision with a faster
                    result.
                  </p>
                </div>
                <div className="text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    Better efficiency
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    With our real-time reporting and trend analysis, we help you
                    in strategizing automation. Our team assures you efficient
                    and effective performance with strategic automation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="relative overflow-hidden bg-white mt-10 lg:mt-12">
          <div className="mx-auto max-w-7xl px-0 lg:px-8 relative">
            <div className="grid grid-cols-1 items-center justify-center lg:gap-y-2 lg:grid-cols-12">
              <div className="flex flex-wrap items-center justify-center lg:px-0 pb-4 lg:pr-12 sm:mx-0 sm:pb-0 lg:col-span-6 order-2 lg:order-1">
                <div className="relative z-10 gap-x-4 px-5 lg:px-0 lg:block lg:gap-x-0 lg:gap-y-1">
                  <p className="text-base text-black font-poppins font-thin leading-8 mb-0">
                    We value our clients’ products as ours and refuse to
                    compromise in terms of quality. Our team of QA experts uses
                    the best and latest QA tools and techniques to evaluate
                    products methodically in different scenarios before
                    delivering. Our QA automation services include end-to-end
                    testing processes such as QA automation planning, selecting
                    of tools, construction and maintenance of QA automation
                    ecosystem, automated test data preparation and
                    implementation, design and maintenance of all test scripts,
                    etc. Using our QA automation services, companies can create
                    digital solutions with zero performance issues at optimized
                    costs.
                  </p>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4 mb-0">
                    Considering the Quality Assurance phase as equally
                    significant as the Design &amp; Development phase, we
                    maximize the performance of each software application or
                    product by carrying out rigorous testing processes for all
                    aspects: compatibility, performance, behavioral,
                    practicality, quantifiability, etc.
                  </p>
                </div>
              </div>
              <div className="lg:col-span-6 order-1 lg:order-2 mb-2 lg:mb-2">
                <div className="relative w-auto overflow-hidden lg:mt-0 lg:w-[67.8125rem]">
                  <div className="w-full h-full">
                    <StaticImage
                      src="../../images/ferenc-almasi-eYpcLDXHVb0-unsplash1.png"
                      alt=""
                      layout="constrained"
                      placeholder="blurred"
                      quality={95}
                      height={350}
                      className="w-full"
                      formats={["auto", "png"]}
                    />
                  </div>
                  <div className="w-full h-full mt-4">
                    <StaticImage
                      src="../../images/fotis-fotopoulos-LJ9KY8pIH3E-unsplash1.png"
                      alt=""
                      layout="constrained"
                      placeholder="blurred"
                      quality={95}
                      height={350}
                      className="w-full"
                      formats={["auto", "png"]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="bg-white mt-10 lg:mt-12">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-left text-2xl font-poppins font-semibold captalize">
              Get One Step Ahead With Our QA Automation Services
            </h2>
            <div className="mt-6 ml-2 lg:ml-0">
              <Timeline
                items={[
                  "Technology Selection & Validation",
                  "Deployment Design",
                  "Deployment Automation",
                  "Configuration & Release Management",
                  "Performance Testing & UI Testing",
                ]}
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default QAAutomation;
